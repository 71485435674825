//import utilActions from '../../utils/actions';

/*const sampleAction = {
	id: 'idSampleAction',
	selectionType: 'single', // [single|multiple|general]
	label: 'action.equipamiento.sampleAction',
	functionality: null, // set the functionality if needed
	showInForm: true,
	checkAvailability: function (/-vue, registries-/) {
		// Validation to execute action
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-equipamientoSampleAction-' + model.name + '-show', row);
	}
};*/

const DocumentosAction = {
	id: 'documentosAction',
	selectionType: 'single',
	label: 'action.equipamiento.documentosaduanero',
	functionality: 'READ_DOCUMENTOSADUANEROEQUIPAMIENTO',
	checkAvailability: function(/*vue, registries*/) {
		return true;
	},
	runAction: function(vue, action, model /*, registries*/) {
		//const nbspObjectPk = utilActions.getPkFromModel(model, registries[0]);
		var nbspObjectPk = { idequipamiento: model[0].idequipamiento };
		const pkStr = JSON.stringify(nbspObjectPk);
		const pk64 = this.$puiUtils.utf8ToB64(pkStr);
		model[0].tabFromAction = 'documentos';

		const thisModel = this.$store.getters.getModelByName(action.name);
		thisModel.tabFromAction = 'documentos';

		const params = {
			router: this.$router,
			url: `${action.name}/read/${pk64}/`,
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};

const generaqrAction = {
	id: 'idGeneraqrAction',
	selectionType: 'single', // [single|multiple|general]
	label: 'action.equipamientoqrequip.generaqrAction',
	functionality: 'WRITE_EQUIPAMIENTOQR', // set the functionality if needed
	showInForm: true,
	checkAvailability: function(/*vue, registries*/) {
		// Validation to execute action
		return true;
	},
	runAction: function(action, model, registries) {
		const objectPk = {};
		var row = registries[0];
		if (row) {
			// Get PK for the header
			for (var index in model.columns) {
				const column = model.columns[index];
				if (column.isPk) {
					objectPk[column.name] = registries[0][column.name];
				}
			}
		} else {
			row = { contenidoqr: null };
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-equipamientoqrequipGeneraqrAction-equipamientoqrequip-show', row);
	}
};

export default {
	gridactions: [DocumentosAction, generaqrAction],
	formactions: [DocumentosAction]
};
