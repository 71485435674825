import { render, staticRenderFns } from "./EquipamientoGrid.vue?vue&type=template&id=4847489a"
import script from "./EquipamientoGrid.vue?vue&type=script&lang=js"
export * from "./EquipamientoGrid.vue?vue&type=script&lang=js"
import style0 from "./EquipamientoGrid.vue?vue&type=style&index=0&id=4847489a&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports