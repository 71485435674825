<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			readOnly
		></pui-datatable>
		<equipamiento-modals :modelName="modelName"></equipamiento-modals>
		<equipamientoqrequip-modals :modelName="modelNameQR" :modelNameOrigen="modelName"></equipamientoqrequip-modals>
	</div>
</template>

<script>
import equipamientoActions from './EquipamientoActions';
import equipamientoModals from './EquipamientoModals.vue';
import equipamientoqrequipModals from '../equipamientoqrequip/EquipamientoqrequipModals.vue';

export default {
	name: 'equipamiento-grid',
	components: {
		'equipamiento-modals': equipamientoModals,
		'equipamientoqrequip-modals': equipamientoqrequipModals
	},
	data() {
		return {
			modelName: 'equipamiento',
			modelNameQR: 'equipamientoqrequip',
			actions: equipamientoActions.gridactions,
			modelColumnDefs: {
				lsp: {
					createdCell: (td, cellData, rowData) => {
						if (td.parentNode) {
							if (rowData.lsp === 'S') {
								td.parentNode.classList.add('lsppositivo');
							} else if (rowData.lsp === 'N') {
								td.parentNode.classList.add('lspnegativo');
							}
						}
					}
				}
			}
		};
	}
};
</script>

<style lang="postcss">
.lspnegativo {
	color: rgb(169, 68, 66);
	background-color: rgba(236, 193, 193, 0.8) !important;
	//font-weight: bold;
}
.lsppositivo {
	color: rgb(4, 68, 2);
	background-color: rgba(193, 243, 184, 0.8) !important;
	//font-weight: bold;
}
</style>
